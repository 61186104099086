var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: {
            content: _vm.filters.date(_vm.data.date, "DD/MM/YYYY HH:mm"),
            hideOnTargetClick: false,
            trigger: _vm.innerWidth < 768 ? "click" : "hover",
          },
          expression:
            "{ content: filters.date(data.date, 'DD/MM/YYYY HH:mm'), hideOnTargetClick: false, trigger: innerWidth < 768 ? 'click' : 'hover' }",
        },
      ],
      staticClass: "text-capitalize whitespace-nowrap",
    },
    [
      _vm.data.date
        ? _c("img", {
            staticClass: "m-i-end-1ch",
            attrs: { src: "/assets/img/future_order.svg", alt: "Future order" },
          })
        : _vm._e(),
      _c("span", { domProps: { textContent: _vm._s(_vm.typeItemPrinted) } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }